

.ts-sp-sc {
    height: 100vh;
    width: 100%;
}

.ts-sp-sc .l-side {
    width: 50%;
    height: inherit;
    background-color: #fff;
    float: left;
    padding: 80px
}

.ts-sp-sc .r-side {
    width: 50%;
    height: inherit;
    float: left;
}
.ts-sp-sc .r-side img {
    width: 90%
}

.ts-sp-sc .l-side h4 {
    width: 100%;
}

.ts-sp-sc .l-side h5 {
    width: 100%;
}

.ts-btn {
    height: 80px;
    width: 100%;
    background-color: #F0F0F0;
    border-radius: 80px;
    transition: all .2s ease-in-out;
    margin-bottom: 40px;
}
.ts-btn:hover {
    transform: scale(1.02);
}

.ts-btn img {
    height: 40px;
    margin-left: 30px;
    float: left;
}
.ts-text {
    margin-left: 30px;
}
.ts-text h4 {
    font-size: 16pt;
    margin-bottom: 10px;
    color: #0A258E !important;
    margin: 0;
    margin-bottom: 5px;
}
.ts-text h5 {
    font-size: 12pt;
    margin: 0;
    color: black !important;
}

.desc-area {
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 20px;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
.btn-area {
    padding: 10px;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}


.logo-img-form {
    width: 400px;
    height: 500px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
}

.logo-img-form h3 {
    margin: 0;
    font-size: 24pt;
    text-align: center;
    margin-bottom: 40px;
}

.logo-area{
    background-color: #ffffff;
    width: 350px;
    height: 250px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    overflow: hidden;
}

.logo-area img {
    width: 200px;
   
}
.upload-icon{
    margin-left: auto;
    margin-right: auto;
}

.company-logo-btns {
    margin-left: auto;
    margin-right: auto;
    width: 350px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.rep-freq {
    height: 500px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
}

.rep-freq h3 {
    text-align: center;
    font-size: 24pt;
    margin: 0;
    margin-bottom: 10px;
}

.rep-freq h5 {
    margin-bottom: 100px;
    font-size: 11pt;
    color: #575656;
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
}
.reports-checkboxes{
    /* background-color: rgb(255, 255, 255); */
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    

}
