
/* STYLING FOR SPLIT SCREEN */
.bg-1 .left-side {
    height: calc(100vh - 64px);
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.bg-1 
.left-side 
.left-side-content {
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
}

.bg-1 .right-side {
    width: 50vw;
    height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.landing-image {
    height: 80vh;
    width: 80%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-1 .split-screen{
    float: left;
}

.bg-1 
.left-side 
.left-side-content h3 {
    font-weight: 400;
}
.bg-1 
.left-side 
.left-side-content h4 {
    font-weight: 300;
}
.bg-1 
.left-side 
.left-side-content h5 {
    font-weight: 300;
    font-size: 16pt;
}

.bg-1 
.left-side 
.left-side-content
.link-area{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.landing-links{
    font-size: 12pt;
}

.link-area ul li {
    margin-top: 5px;
}
.link-area ul li :hover {
    color: grey
}

/* STYLING FOR SPLIT SCREEN */


/* STYLING FOR CONTENT  */
.landing-content {
    width: 80vw;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    min-height: calc(100vh - 64px);
    padding: 20px;
    margin-bottom: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
}
.landing-content h4 {
    font-weight: bold;
    font-size: 16pt;
    margin-bottom: 50px;
    width: 100%;
    background-color: #1741FF;
    line-height: 80px;
    color: white;
    padding: 10px;
    box-sizing: border-box;
}
.landing-content h5 {
    font-weight: bold;
    font-size: 12pt;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.landing-content p{
    margin-left: 20px;
}
.landing-content .bold-text {
    font-weight: bold;
}
.content-banner {
    line-height: 50px;
    padding: 20px;
    box-sizing: border-box;
    color: white;
    font-size: 20pt;
    border-radius: 5px;
    font-weight: 400;
}
.ml-20 {
    margin-left: 20px;
}
/* STYLING FOR CONTENT  */


 /****************************** STYLING FOR PRIVACY POLICY********************** */
 /* .privacy-policy-parent {
    min-height: calc(100vh - 64px);
    margin-bottom: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
}
.privacy-policy-parent h4 {
    font-weight: bold;
    font-size: 16pt;
    margin-bottom: 50px;
    width: 100%;
    background-color: #1741FF;
    line-height: 80px;
    color: white;
    padding: 10px;
    box-sizing: border-box;
}
.privacy-policy-parent h5 {
    font-weight: bold;
    font-size: 12pt;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}
.privacy-policy-parent p{
    margin-left: 20px;
}
.privacy-policy-parent .bold-text {
    font-weight: bold;
} */


/******************************END OF STYLING FOR PRIVACY POLICY********************** */