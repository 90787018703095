

.account-suspended{
    height: 100vh;
    position: relative;
}

.account-suspended .l-side {
    width: 40%;
    height: 100vh;
    float: left;
    /* display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid green;  */
}

.account-suspended .r-side {
    width: 60%;
    float: left;
    height: 100vh;
    float: left;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.account-suspended .l-side img{
    margin-left: auto;
    margin-right: auto;
}
.suspended-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}


.r-side-one {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.verify-email {
    width: 400px;
    height: 400px;
    /* background-color: red; */
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    box-sizing: border-box;
    padding: 10px;
}

.verify-email img {
    width: 150px;
}

.verify-email h3{
    font-size: 18pt;
    text-align: center;
    color: black;
}
.verify-email h4{
    font-size: 14pt;
    text-align: center;
    color: #919191;
}
.verify-email h5{
    font-size: 12pt;
    text-align: center;
    color: #919191;
    margin-top: 50px;
}

.verify-email button {
    width: 100%;
    margin-top: 20px;
}


/* STYLING FOR SCAN ERROR */
.scan-error {
    height: 60vh;
    width: 100%;
    margin-top: 40px;
    padding: 20px;
}
/* END OF STYLING FOR SCAN ERROR */

/* STYLING FOR IMAGE UPLOAD */
.image-upload-success {
    padding-top: 80px;
    display: flex;
    flex-direction: column;
}
.check-mark{
    color: green;
    font-size: 8em;
}

.uploading-image {
    margin-top: 60px;
    font-size: 5em;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.loading-progress{
    width: inherit;
}
/* STYLING FOR IMAGE UPLOAD */