
.report-cont{
    height: 100vh;
}
.report-parent {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 100px;


}
.report-actions{
    width: 100%;
    line-height: 100px;
    box-sizing: border-box;
    text-align: right;
}
