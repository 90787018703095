.user-profile{
    width: 60vw;
    background-color: #f2f2f2;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
}
.profile-picture{
    background-color: #fff;
    width: 200px;
    height: 250px;
    margin-right: 20px;
}

.user-profile ul li {
    font-size: 16pt;
    font-weight: bold;
}
.profile-text{
    font-weight: normal;
}