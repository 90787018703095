.map-parent {
    height: 100vh;
    width: 100%;
    padding-top: 100px;
}
.map-frame {
    height:100vh;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
}

.edit-map{
    height: 100vh;
    padding-top: 60px;
    overflow: auto;
    padding-bottom: 250px;
}
.edit-map-fields{
    height: 200px;
    width: 500px;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    padding: 10px
}
.edit-map-container {
    width: 80%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}
.edit-map-frame{
    width: 100%;
    height: 100%;
}