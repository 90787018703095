.visitor-list {
    width: 100%;
    background-color: #fff;
    padding: 5px;
    border-radius: 5px;
    overflow: auto;
}



table.highlight > tbody > tr:hover {
    background-color: rgb(189, 189, 189);
}

table.highlight > tbody > tr {
    background-color: #f5f5f5;
}
table.highlight > thead > tr {
    background-color: #1741FF;
    color: white;
}


table {
    width: 100%;
    font-size: 13px;
  }
  
  /* Table Fixed Column */
  
  table th,
  table td {
    padding-right: 24px;
    padding-left: 24px;
    white-space: nowrap;
    
  }
  .visitor-row:hover {
    cursor: pointer;
  }


  /* STYLING FOR VISITOR MODAL  */
  .visitor-modal-parent {
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      padding-top: 80px;
      left: 0;
      display: none;
  }
  .visitor-modal {
    min-height: 400px;
    width: 60%;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    position: relative;
  } 
  .visitor-modal-parent .close-btn {
    position: fixed;
    top: 100px;
    right: 100px;
    color: rgb(218, 218, 218);
    font-size: 30pt;
    cursor: pointer;
  }

  .visitor-data-key{
      font-size: 12pt;
      font-weight: bold;
      color: grey;
  }
  .visitor-data-value {
      font-size: 11pt;
      font-weight: normal;
      margin-left: 10px;
  }

  .visitor-title {
      line-height: 50px;
      background-color: #1741FF;
      color: #fff;
      border-radius: 50px;
  }

  
  /* STYLING FOR VISITOR MODAL  */

  /* STYLING FOR VISITOR FILTERS */
  .visitor-filter {
    line-height: 60px;
    margin-bottom: 40px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
 
  .visitor-filter span {
    cursor: pointer;
  }
  .splitter {
    width: 2px;
    height: 30px;
    background-color: rgb(173, 162, 162);
  }

  .visitor-filter span{
    width: 30%;
    text-align: center;
    box-sizing: border-box  ;
  }
  .visitor-filter span:hover{
    background-color: rgb(199, 199, 199);
  }
  .filter-btn-active {
    background-color: rgb(199, 199, 199);
  }

 .custom-filter {
   background-color: #fff;
   border-radius: 5px;
   margin-bottom: 40px;
   box-sizing: border-box;
   padding: 20px;
   transition: height 2s;
 }
 
 .filter-date-inpt {
   width: 200px;
   padding-right: 10px;
 }

 .more-actions{
   display: flex;
   flex-direction: row;
   justify-content: space-between;
 }
 .more-actions-body {
   position: relative;
 }
 .buttons-container {
   /* min-height: 300px; */
   position: relative;
   padding-bottom: 50px;
 }

.export-btn {
  position: absolute;
  right: 20px;
  bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.filter-date {
  width: 200px;
}

.report-period {
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  background-color: #fff;
}

.custom-date-filter{
  font-size: 14pt;
  margin-bottom: 50px;
}
.date-filt-btn {
  width: 150px;
  align-items: baseline;
  margin-top: 30px;
}
  /* STYLING FOR VISITOR FILTERS */