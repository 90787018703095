.dashboard-parent {
    background-color: #f2f2f2;
    height: 100vh;
    width: 100vw;
}
.dashboard-left {
    width: 50%;
    float: left;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.dashboard-left img {
    width: 50%;
}



.dashboard-right {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 10px;
    height: inherit;
    overflow: auto;
    margin-bottom: 100px;
    padding-bottom: 200px;
}

.dashboard-welcome {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;

}

.dashboard-locations {
    /* height: 1000px; */
    padding: 20px;
    box-sizing: border-box;
}

.location-tile {
    height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    transition: all .2s ease-in-out;
    cursor: pointer;
}
.location-tile:hover {
    transform: scale(1.02);
}

.launcher {
    height: 50px;
    width: 100%;
    /* background-color: blue; */
    box-sizing: border-box;
    padding-right: 100px;
}
.more-icons {
    color: #fff ;
    font-size: 22pt;
    background-color: #1741ff;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    position: absolute;
    right: 20px;
    bottom: 150px;
}
.dash-links {
    /* height: 200px; */
    width: 200px;
    position: absolute;
    right: 20px;
    bottom: 200px;
    text-align: right;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
}
.dash-links ul {
    padding: 5px
}
.dash-links ul li {
    margin-bottom: 10px;
}
.dash-links ul li :hover{
    margin-bottom: 10px;
    font-weight: bold;
}







::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #1741FF; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1741FF; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1741FF; 
  }


  /* STYLING FOR REFERRAL REMINDER */
  .referral-reminder{
      height: 200px;
      background-color: #fff;
  }
  .referral-reminder h4{
      font-size: 16pt;
  }
  .referral-reminder h5{
      font-size: 12pt;

  }
  .ref-rem-foot .filled-btn{
      margin: 10px;
  }

  /* STYLING FOR REFERRAL REMINDER */