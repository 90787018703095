.auth-form {
    padding-top: 80px;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 100px;
    padding-bottom: 100px;
}

.auth-form h3{
    margin: 0;
    margin-bottom: 40px;
}
.auth-form h4 {
    font-size: 12pt;
    margin-bottom: 120px;
}
.auth-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.auth-action {
    display: flex;
    flex-wrap: wrap;
  }
  
  .auth-action-left {
    padding: 20px;
    flex: 50%;
    text-align: left;
  }
  
  .auth-action-right {
    flex: 50%;
    text-align: right;
  }