/* .location-tile {
    background-color: green;

} */
.location-tile img {
    height: 80px;
    margin-left: 15px;
}
.location-summary-text{
    background-color: #fff;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    margin-left: 20px;
}

.location-summary-text h4{
    font-size: 15pt;
    font-weight: bold;
    margin: 0;
}
.location-summary-text h5{
    font-size: 14pt;
    margin: 0;
}

.summary-parent {
    /* height: 200px; */
    width: 100%;
    background-color: #fff;
  }
  .summary-parent .title {
    font-size: 18pt !important;
    color: #ABABAB !important;
  }
  
  .location-buttons ul li {
    line-height: 50px;
    padding-left: 10px;
    color: #727272;
  }
  
  .location-buttons ul li:hover {
    background-color: #B2BCEC;
  }
  
  .location-stats {
    /* height: 130px; */
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 40px;
  }
  .location-stats td {
    color: #B5B5B5;
    font-weight: bold;
    font-size: 18pt;
  }

  /* CREATE LOCATION  */
  .create-location-form {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    padding-top: 100px;
  }
  .create-location-form h3 {
    font-size:26pt;
    margin-bottom: 50px;

  }
  /* CREATE LOCATION  */

  
/* *****************************STYLING FOR QR CODE*****************************  */
.qr-parent {
  padding-top: 50px;
  overflow: auto;
  padding-bottom: 100px;
}
.qr-parent h4 {
  /* font-size: 20pt; */
  margin: 0;
}
.qr-parent h5 {
  font-size: 14pt;
}
.qr-container {
  width: 300px;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  /* height: 500px; */
  margin-top: 20px;
  box-sizing: border-box;
  padding: 5px;
  border: 1px solid rgb(221, 221, 221);
}

.qr-container h4 {
  font-size: 14pt;
  margin-top: 20px;
}
 .location-logo {
  height: 100px;
  width: 100%;
  background-color: #f2f2f2;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.qr-container .location-logo img {
  width: 100px;
  margin-left: auto;
  margin-right: auto;
}


.qr-container h5 {
  font-size: 14pt;
}

.qr-area {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}


.qr-container .ingia-logo {
  text-align: right;
}

.qr-container .ingia-logo img{
  width: 100px;
}

.ingia-brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
}
.ingia-brand h5 {
  font-size: 10pt;
}

.qr-btns{
  width: 320px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.resize {
  width: 100px;
}

/* *****************************END OF STYLING FOR QR CODE*****************************  */

/* QUESTION STYLING */
.add-question-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); ;
  position: fixed;
  z-index: 20;
  display: none;
}
.add-question-modal .close-btn{
  position: fixed;
  top: 100px;
  right: 100px;
  color: rgb(218, 218, 218);
  font-size: 30pt;
  cursor: pointer;
}
.add-question-content {
  min-height: 400px;
  width: 60%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 10px;
  padding: 10px 20px 10px 20px;
  position: relative;
} 
.create-question{
  position: absolute;
  bottom : 15px;
  right: 10px;
}
.mandatory-field {
  width: 100%;
  background-color: #f2f2f2;
  line-height: 40px;
}
.radio-buttons{
  width: 100%;
}
.question-delete{
  height: 20px;
  border-radius: 5px;
  color: #fff;
  background-color: #B2BCEC;
  text-align: center;
  cursor: pointer;
  width: 55px;
}
.primary-questions {
  margin-bottom: 20px;
}
.question-descriptions {
  font-size: 10pt;
}
/* END OF QUESTION STYLING */

/* STYLING FOR WHAT3WORDS */
.location-address {
  /* line-height: 50px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.somer { 
  display: none;
}

.map-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
  padding-top: 150px;

}
.what-3-words{
  height: 60vh;
  width: 65vw;
  background-color: #fff;
}
.map-frame {
  height: 60vh;
  width: 70vw;
}
.map-description {
  width: 14vw;
  height: 200px;
  z-index: 5;
  background: #fff;
  position: absolute;
  right: 2vw;
  top: 40vh;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
}

.map-description h3 {
  background-color: #f2f2f2;
  padding: 5px;
  font-size: 14pt;
}
/* STYLING FOR WHAT3WORDS */

/* LOCATION DELETION */
.deleting-location  {
  height: 400px !important;
}
.deleting-location h4{
  margin-bottom: 50px;
  background-color: #f2f2f2;
  padding: 20px
}

.delete-location-button a {
  height: 100%;
  width: 100%;
  color: #727272;
}

/* LOCATION DELETION */

/* LOCATION SUSPENSION  */
.location-suspended {
  line-height: 40px;
  float: right;
  position: absolute;
  right: 50px;
  color: rgb(245, 146, 146);
  font-weight: bold;
}
/* LOCATION SUSPENSION  */

/* STYLING FOR LOCATION SUMMARY */

.location-summary-panel {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.summary-circle {
  height: 90px;
  width: 90px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-left: 10px;
}
.summary-circle img {
  width: 90px;
}
.summary-text{
  width: calc(100% - 90px);
  height: 80px;
  background-color: #fff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.summary-text h4{
  color: grey;
  margin: 0;
  font-size: 14pt;
  margin-bottom: 5px;
}
.summary-text h5{
  color: grey;
  margin: 0;
  font-size: 10pt;
}
/* STYLING FOR LOCATION SUMMARY */