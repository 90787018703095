

.checkin-type {
    padding: 10px;
}

.mobile-splash {
    background-color: #1741ff;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}
.mobile-splash img {
    width: 60%;
}

.checkin-type {
    background-color: #fff;
    height: 100vh;
    width: 100vw;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
}
.checkin-type h4{
    font-size: 12pt;
}

.checkin-type-actions {
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 100px;
}
.mobile-checkin {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 30px;
}
.mobile-btn-width {
    width: 100%;
}


.out-of-location{ 
    /* height: 400px; */
    width: 90%;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 10px;
}
.loc-err {
    padding-top: 40px;
}
.out-of-location h5 {
    font-weight: bold;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
}
.out-of-location h6{
    text-align: center;
    margin-bottom: 20px;
}

.activate-geolocation{
    padding-top: 40px;
}

.geo-err {
    padding-top: 40px;
}
.activate-geolocation h5 {
    font-weight: bold;
    color: grey;
    text-align: center;
    margin-bottom: 20px;
}
.activate-geolocation h6{
    text-align: center;
    margin-bottom: 20px;
}

.checkin-parent {
    padding: 10px;
}
.clear-btn{
    line-height: 30px;
    width: 70px;
    background-color: #1741FF;
    color: white;
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
}
.radio {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 10px;
    position: relative;
}
.radio h5 {
    font-size: 12pt;
}

.checkout-parent{
    padding: 10px;
}


/* SUCCESS PAGE  */
.success-page,
.checkout-success{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.success-page h3,
.checkout-success h3{
    font-size: 22pt;
}
.success-page h4,
.checkout-success h4{
    font-size: 18pt;
    font-weight: 500;
    margin-bottom: 20px;
}
.success-page h5,
.checkout-success h5{
    font-size: 20pt;
    color: grey;
    font-weight: 500;
}
.success-page h6,
.checkout-success h6{
    font-size: 14pt;
    color: grey;
    font-weight: 500;
    margin-top: 40px;
}
.success-check {
    font-size: 20pt;
}
.success-checkout{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px blue solid;
    margin-left: auto;
    margin-right: auto;
}
/* SUCCESS PAGE  */

/* ERROR PAGE  */
.error-scanning{
    padding-top: 40px;
    padding-left: 10px;
    padding-right: 5px;
}
.error-scanning h4{
    margin: 0;
}
.error-scanning h5{
    font-size: 20pt;
    font-weight: 500;
    color: grey;
    margin-top: 30px;
    margin-bottom: 30px;
}
.error-scanning h6{
    font-size: 18pt;
    font-weight: 500;
    margin-top: 40px;
}
.error-check{
    margin-top: 40px;
}
/* ERROR PAGE  */