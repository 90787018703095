

/* ***************************SPECIFYING FONTS***************************  */
@font-face {
  font-family: 'SF-Pro';
  src: local('SF-Pro'), url('./assets/fonts/SF-Pro.ttf');
  font-display: block;
}

@font-face{
  font-family: 'SF-Pro-Thin';
  src: local('SF-Pro-Thin'), url('./assets/fonts/SF-Pro-Display-Thin.otf');
  font-display: block;
}

@font-face{
  font-family: 'SF-Pro-Light';
  src: local('SF-Pro-Light'), url('./assets/fonts/SF-Pro-Display-Light.otf');
  font-display: block;
}

@font-face{
  font-family: 'SF-Pro-Black';
  src: local('SF-Pro-Black'), url('./assets/fonts/SF-Pro-Display-Black.otf');
  font-display: block;
}

@font-face{
  font-family: 'SF-Pro-Bold';
  src: local('SF-Pro-Bold'), url('./assets/fonts/SF-Pro-Display-Bold.otf');
  font-display: block;

}

@font-face{
  font-family: 'SF-Pro-Italic';
  src: local('SF-Pro-Italic'), url('./assets/fonts/SF-Pro-Italic.ttf');
  font-display: block;
}
/* *****************************SPECIFYING FONTS*****************************  */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ********************************BRAND COLORS********************************  */
.ingia-blue-bg{
  background-color: #1741ff;
}
.ingia-blue-txt{
  color: #1741ff;
}
.ingia-grey-bg{
  background-color: #f2f2f2;
}
.ingia-grey-txt{
  background-color: #f2f2f2;
}
.ingia-dark-grey-txt {
  color: #ABABAB;
}

/* ********************************BRAND COLORS********************************  */

/* *******************************BUTTON STYLING*******************************  */
/* STANDARD BUTTON  */

/* FILLED BUTTON */
.filled-btn{
  color: #fff;
  background-color: #1741ff;
  min-width: 150px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: background-color 0.5s ease;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}
.filled-btn:hover{
  color: #1741ff;
  background-color: #fff;
  border: 2px solid #1741ff;
}
.filled-btn:focus{
  color: #fff;
  background-color: #1741ff;
  opacity: 0.8;
}
/* FILLED BUTTON */

/* OUTLINE BUTTON */
.outline-btn {
  background-color: #fff;
  color: #1741ff;
  min-width: 150px;
  height: 40px;
  text-align: center;
  border-radius: 50px;
  border: 2px solid #1741ff;
  cursor: pointer;
  transition: background-color 0.5s ease;
}
.outline-btn:hover {
  color: #fff;
  background-color: #1741ff;
  border: 2px solid #1741ff;
}
.outline-btn:focus{
  color: #1741ff;
  background-color: #fff;
  border-radius: 2px solid #1741ff;
}
/* OUTLINE BUTTON */

/* ICON BUTTON */
.icon-btn{
  min-width: 150px;
  text-align: left;
  height: 40px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 12pt;
}
.icon-left .material-icons {
  display: inline-flex;
  vertical-align: text-bottom;
  font-size: 14pt;
  margin-right: 20px;
}
.icon-right .material-icons {
  display: inline-flex;
  vertical-align: text-bottom;
  font-size: 14pt;
  margin-left: 20px;
}
/* ICON BUTTON */

/* *******************************BUTTON STYLING*******************************  */


/* *******************************INPUT STYLING*******************************  */
.input-field label{
  color: #7d7979;
  font-weight: 500;
  margin-left: 20px;
  font-size: 18pt;
}
.input-field input[type=text]:focus + label{
  color: #7e93f2 !important;
}
.input-field input[type=password]:focus + label{
  color: #7e93f2 !important;
}

.input-field input[type=email]:focus + label{
  color: #7e93f2 !important;
}
.input-field input[type=tel]:focus + label{
  color: #7e93f2 !important;
}
.input-field input[type=date]:focus + label{
  color: #7e93f2 !important;
}


.input-field input[type = text ]{
  background-color: #d8dadf;
  border-radius: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 15px;
}
.input-field input[type = password ]{
  background-color: #d8dadf;
  border-radius: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 15px;
}
.input-field input[type = email ]{
  background-color: #d8dadf;
  border-radius: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 15px;
}
.input-field input[type = tel ]{
  background-color: #d8dadf;
  border-radius: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 15px;
}
.input-field input[type = date ]{
  background-color: #d8dadf;
  border-radius: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  margin-top: 15px;
}


.input-field ::placeholder{
  color: #7d7979;
}
.input-field input[type=text]:focus {
  border-bottom: 1px solid #1741ff  !important;
  box-shadow: 0 1px 0 0 #1741ff  !important;
}
.input-field input[type=password]:focus {
  border-bottom: 1px solid #1741ff  !important;
  box-shadow: 0 1px 0 0 #1741ff  !important;
}
.input-field input[type=email]:focus {
  border-bottom: 1px solid #1741ff  !important;
  box-shadow: 0 1px 0 0 #1741ff  !important;
}
.input-field input[type=tel]:focus {
  border-bottom: 1px solid #1741ff  !important;
  box-shadow: 0 1px 0 0 #1741ff  !important;
}
.input-field input[type=date]:focus {
  border-bottom: 1px solid #1741ff  !important;
  box-shadow: 0 1px 0 0 #1741ff  !important;
}
/* *******************************INPUT STYLING*******************************  */



/* BACKGROUND STYLING  */
.bg-1 {
  height: 100vh;
  width: 100vw;
  background-image: url('./assets/images/art/artone.png');
  background-repeat: no-repeat;
  background-position: left;
  background-attachment: fixed;
  background-color: #f2f2f2;
}


.bg-2 {
  height: 100vh;
  width: 100vw;
  background-image: url('./assets/images/art/arttwo.png');
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  background-color: #f2f2f2;
}
.ovfl-aut{
  overflow: auto;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 320px) {
  .bg-1 {
    background-size: 200px;
  }
  .bg-2 {
    background-size: 400px;
  }
}


/* Styling for iphones  */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 600px)
and (-webkit-min-device-pixel-ratio: 2){
  .bg-1 {
    background-size: 60%;
  }
  .bg-2{
    background-size: 400px;
  }
}


@media only screen and (min-width: 321px) {
  .bg-1{
    background-size: 300px;
  }
  .bg-2{
    background-size: 400px;
  }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .bg-1{
    background-size: 300px;
  }
  .bg-2{
    background-size: 500px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bg-1{
    background-size: 300px;
  }
  .bg-2{
    background-size: 600px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bg-1{
    background-size: 300px;
  }
  .bg-2{
    background-size: 700px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .bg-1{
    background-size: 300px;
  }
  .bg-2{
    background-size: 650px;
  }
}


/* STYLING FOR SPLIT SCREEN  */


/* BACKGROUND STYLING  */


/* SPLIT STYLING  */


/* Medium devices (landscape tablets, 768px and below) */


.split-left{
  width: 50%;
  float: left;
}
.split-right {
  width: 50%;
  float: left;
}

@media only screen and (max-width: 768px) {
  .split-left{
    width: 100%;
    height: 45%;
  }
  .split-right {
    width: 100%;
    height: 50%;
  }
  
}
/* SPLIT STYLING  */

/* MARGIN STYLING */
.mt-5 {
  margin-top: 5px;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.mt-30{
  margin-top: 30px;
}
.mt-40{
  margin-top: 40px;
}
.mt-50{
  margin-top: 50px;
}
.mt-60{
  margin-top: 60px;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-30{
  margin-bottom: 30px;
}
.mb-40{
  margin-bottom: 40px;
}
.mb-50{
  margin-bottom: 50px;
}
.mb-60{
  margin-bottom: 60px;
}
.mb-70{
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 80px;
}
.mb-100 {
  margin-bottom: 80px;
}

.mr-10 {
  margin-right: 10px;
}
.mr-20{
  margin-right: 20px;
}

.ml-10 {
  margin-left: 10px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-30 {
  margin-left: 30px;
}
.ml-40 {
  margin-left: 40px;
}
.ml-50 {
  margin-left: 50px;
}
.ml-60 {
  margin-left: 60px;
}

/* MARGIN STYLING */

/* PADDING STYLING  */
.pl-50{
  padding-left: 50 !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}

/* PADDING STYLING  */




/* STYLING FOR MANAGEMENT CONSOLE */
.management-panel {
  width: 100%;
  min-height: 100vh;
  background-color: #F2F2F2;
}

.management-panel ::-webkit-scrollbar {
  width: 10px;
}
.management-panel ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(255, 255, 255); 
  border-radius: 10px;
}
.management-panel ::-webkit-scrollbar-thumb {
  background: #1741FF; 
  border-radius: 10px;
}
.management-panel ::-webkit-scrollbar-thumb:hover {
  background: #4162f3; 
}


.side-panel {
  width: 25%;
  /* background-color: grey; */
  float: left;
  padding: 20px;
  box-sizing: border-box;
}

.panel-container {
  width: 100%;
  /* height: 600px; */
  /* background-color: #fff; */
  /* overflow: auto; */
  overflow-x: scroll;
}


.main-panel {
  width: 75%;
  /* height: 1000px; */
  float: left;
  padding: 20px;
  box-sizing: border-box;
}


.side-panel-content {
  width: 100%;
  /* height: 200px; */
  box-sizing: border-box;
  padding: 10px;
}
.main-panel-content {
  width: 100%;
  height: 2000px;
  box-sizing: border-box;
  padding: 10px;
  height: 90vh;
  
}
/* END OF STYLING FOR MANAGEMENT CONSOLE */

/* BANNER STYLING  */
.banner {
  height: 50px;
  overflow: hidden;
  background-color: #1741ff;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
  align-items: center;
  transition: height 2s;
}
.exit-call-to-action {
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
}

.exit-call-to-action:hover{
  background-color: #4768ff;

}
/* END OF BANNER STYLING  */

/* STYLING FOR PRINT  */
@media print {
  .navbar-fixed,
  .when-print,
  .qr-btns {
    display: none;
  }
}
/* END OF STYLING FOR PRINT  */


