
.contact-form-container {
    height: 50vh;
    width: 40vw;
}
.contact-btn {
    float: right;
    padding-right: 10px;
}
.contact-success {
    position:fixed;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.4);
    /* background:rgba(0, 0, 0, 1); */
    z-index: 999;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading-page {
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999;
}
.loading-page h4 {
    font-size: 14pt;
}
.loading-contact {
    height: 100vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.contact-submitted {
    height: 100vh;
    width: 50vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}