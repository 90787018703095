
/* BRAND LOGO  */
.brand-logo img {
    display: inline-block;
    height: 65px;
}

.sidenav-trigger {
    display: none;
    margin-top: 10px;
    border: none;
    cursor: pointer;
    color: #1741ff;
    background-color: #eeeeee;
}

.sidenav-trigger:hover {
    background-color: transparent !important;
}

.mobile-profile-initials {
    font-weight: bold !important;
    font-size: 20pt !important;
    height: 100px !important;
    background-color: #e0e0e0;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
}
.mobile-profile-initials h5 {
    margin: 0;
}
.navbar-fixed {
    z-index: 999;
}
.mobile-links li {
    line-height: 60px;
}
.mobile-links .li-btns {
    padding-left: 20px;
}
.navlink-btn {
    padding-left: 30px;
    margin-right: 15px;
}
.navlink-btn .material-icons {
    font-size: 14pt;
    margin-left: 20px;
}
.mob-navlink-btn {
    background-color: #1741ff;
    width: 100%;
    color: #FFF;
}
.mob-navlink-btn:hover{
    color: #1741ff !important;
    border-left: 6px solid #1741ff;
}
.active-link {
    background: #d8d8d8;
}


 /* Extra small devices (phones, 600px and down) */
 @media only screen and (max-width: 600px) {
    .sidenav-trigger { 
        display: block;
    }
 }

 /* Small devices (portrait tablets and large phones, 600px and up) */
 @media only screen and (min-width: 600px) {
    .sidenav-trigger { 
        display: block;
    }
 }
 
 /* Medium devices (landscape tablets, 768px and up) */
 @media only screen and (min-width: 768px) {
    .sidenav-trigger { 
        display: block;
    }
 }
 
 /* Large devices (laptops/desktops, 992px and up) */
 @media only screen and (min-width: 992px) {
    .sidenav-trigger { 
        display: none;
    }
 }
 
 /* Extra large devices (large laptops and desktops, 1200px and up) */
 @media only screen and (min-width: 1200px) {
    .sidenav-trigger { 
        display: none;
    }
 } 
  /* BRAND LOGO  */
