.refer-user-parent {
    height: 100vh;
    width: 100%;
    z-index: 999;
    padding-bottom: 200px;
  }
  

  .refer-user-parent .l-side {
    width: 50%;
    height: 80vh;
    z-index: -3;
    float: left;
    margin: 0 auto;
    display : flex;
      flex-direction: column;
      justify-content:space-around;
    box-sizing: border-box;
    padding: 80px;
  }
  .refer-user-parent .l-side h4{
    font-size: 16pt;
  }
  .refer-user-parent .r-side {
    width: 50%;
    /* background-color: rgba(216, 100, 5, 0.2); */
    height: 100vh;
    float: left;
    box-sizing: border-box;
    position: relative;
    padding-bottom: 200px;
  }

  .refer-user-form {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 100px;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 10px;
  }
  .rf-btn {
      text-align: right;
      margin-top: -10px;
  }